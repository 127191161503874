import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Cardinfo from "../Components/Cardinfo";
import { color_exports } from "../Styles/Color_exports";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedbtnsearch from "../Components/Modifiedbtnsearch";
import ReactQuill from "react-quill";
import { reporteditorstyles } from "../Styles/Contentmanagement_styles";
import reportdetailimg from "../assets/Images/reportdetailimg.png";
import { reports_api, sendmailreport_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import chatbg1 from "../assets/Images/chatbg1.png";

const Reports = () => {
  const reporteditorstyle = reporteditorstyles();
  const [reports, setReports] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [noResults, setNoResults] = useState(false); // Flag for no results
  const [userMessage, setUserMessage] = useState(""); // New state for userMessage
  const [isReportSent, setIsReportSent] = useState(false);

  const fetchData = async (searchText = "") => {
    try {
      const params = searchText
        ? { min: 0, max: 50, searchText }
        : { min: 0, max: 50 };
      const response = await reports_api(params);
      if (
        response &&
        response.message === "Successfully retrived Reports data"
      ) {
        setReports(response.data.rows);
        setImageBaseUrl(response.data.imageBaseUrl);
        setNoResults(response.data.rows.length === 0); // Set noResults flag based on data length
      } else {
        console.error(response?.message || "Failed to fetch reports");
        enqueueSnackbar(response?.message || "Failed to fetch reports", {
          variant: "error",
        });
        setNoResults(true); // Set noResults flag if an error occurs
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message || "Failed to fetch reports", {
        variant: "error",
      });
      setNoResults(true); // Set noResults flag if an error occurs
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async (reportId) => {
    const report = reports.find((r) => r.reportId === reportId);
    if (!report) return;

    const { firstName, email, subject, message: userMessage } = report;
    const description = message[reportId] || "";

    const parser = new DOMParser();
    const descriptionDoc = parser.parseFromString(description, "text/html");
    const isDescriptionEmpty = !descriptionDoc.body.textContent.trim();

    if (isDescriptionEmpty) {
      enqueueSnackbar("Please enter a reply before sending.", {
        variant: "warning",
      });
      return;
    } else {
      setErrors((prev) => ({ ...prev, [reportId]: "" }));
    }

    try {
      const response = await sendmailreport_api({
        reportId,
        name: firstName,
        email,
        subject,
        message: description,
        userMessage,
      });

      if (
        response &&
        response.message === "Successfully Send Mail Report to User"
      ) {
        enqueueSnackbar(response.message, { variant: "success" });
        setMessage((prev) => ({ ...prev, [reportId]: "" }));
        setUserMessage(""); // Clear the userMessage state after sending
        setIsReportSent(true); // Set the state to true after sending the report
      } else {
        console.error(response?.message || "Failed to send mail");
        enqueueSnackbar(response?.message || "Failed to send mail", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message || "Failed to send mail", {
        variant: "error",
      });
    }
  };

  const handleQuillChange = (value, reportId) => {
    setMessage((prev) => ({
      ...prev,
      [reportId]: value,
    }));
    setErrors((prev) => ({ ...prev, [reportId]: "" }));
  };

  const handleReportClick = (report) => {
    setSelectedReport(report);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "color",
    "link",
    "image",
  ];

  useEffect(() => {
    fetchData(searchText);
  }, [searchText]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth={"lg"}>
      <Grid container mt={1} spacing={2}>
        <Grid item md={4.5} mb={5}>
          <Paper elevation={5} className={reporteditorstyle.searchpaper}
            sx={{
              maxHeight: "500px", // Set a fixed height for the scrollable area (adjust as needed)
              overflowY: "auto", // Enables vertical scrolling
            }}
          >
            <Grid container direction={"column"} spacing={2}>
              <Grid item container>
                <Grid item md={"auto"}>
                  <Modifiedbtnsearch
                    width={"19.5rem"}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
              </Grid>
              {noResults ? (
                <Grid item>
                  <Typography className={reporteditorstyle.noresults}>
                    No reports available
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  sx={{
                    marginTop: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  {reports.map((report) => (
                    <Grid
                      item
                      key={report.report_id}
                      sx={{ minHeight: "70px" }}
                      onClick={() => handleReportClick(report)}
                    >
                      <Cardinfo report={report} imageBaseUrl={imageBaseUrl} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={7.5} mb={5}>
          <Paper
            elevation={5}
            className={reporteditorstyle.searchpaper}
            sx={{
              maxHeight: "500px", // Set a fixed height for the scrollable area (adjust as needed)
              overflowY: "auto", // Enables vertical scrolling
            }}
          >
            <Grid container direction={"column"} spacing={2}>
              {selectedReport ? (
                <>
                  <Grid item container>
                    <Grid item md={"auto"} spacing={2}>
                      <img
                        src={
                          selectedReport.profileImages[0]?.image1
                            ? `${imageBaseUrl}${selectedReport.profileImages[0].image1}`
                            : reportdetailimg
                        }
                        alt="profile"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md
                      container
                      direction={"column"}
                      alignSelf={"center"}
                      ml={2}
                    >
                      <Grid item container spacing={2}>
                        <Grid item md={"auto"}>
                          {selectedReport.firstName}
                        </Grid>
                        <Grid item md={1}>
                          {/* <Verify /> */}
                        </Grid>
                      </Grid>
                      <Grid item sx={{ color: color_exports.icon_color }}>
                        {selectedReport.permanentCity}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container mt={2} direction={"column"}>
                    <Stack spacing={2}>
                      <Grid item sx={{ color: color_exports.icon_color }}>
                        Subject
                      </Grid>
                      <Grid item>{selectedReport.subject}</Grid>
                    </Stack>
                  </Grid>
                  <Grid item mt={2} container direction={"column"}>
                    <Stack spacing={2}>
                      <Grid item sx={{ color: color_exports.icon_color }}>
                        Message
                      </Grid>
                      <Grid item>{selectedReport.message}</Grid>
                    </Stack>
                  </Grid>
                  <Grid item container direction={"column"} mt={2}>
                    <Stack spacing={1}>
                      <Grid item sx={{ color: color_exports.icon_color }}>
                        Reply
                      </Grid>
                      <Grid item>
                        {selectedReport.emailSentStatus ? (
                          // Display adminMessage only if it exists
                          selectedReport.adminMessage ? (
                            <Typography color="textSecondary">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: selectedReport.adminMessage,
                                }}
                              />
                            </Typography>
                          ) : null
                        ) : (
                          // Render ReactQuill editor when emailSentStatus is false
                          <>
                            <ReactQuill
                              className={reporteditorstyle.editorbodyterms}
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              style={{ height: "90%", width: "95%" }}
                              value={message[selectedReport.reportId] || ""}
                              onChange={(value) =>
                                handleQuillChange(
                                  value,
                                  selectedReport.reportId
                                )
                              }
                            />
                            {errors[selectedReport.reportId] && (
                              <Typography color="error" variant="caption">
                                {errors[selectedReport.reportId]}
                              </Typography>
                            )}
                          </>
                        )}
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid item container justifyContent={"flex-end"} mt={1}>
                    {!selectedReport?.emailSentStatus && ( // Only render buttons when emailSentStatus is false
                      <>
                        <Grid item>
                          <Modifiedbutton data={"Cancel"} bgcolor={"#F2F2F2"} styles={{ color: "#9F9F9F" }} swidth={"6rem"} onClick={() => setSelectedReport(null)} />
                        </Grid>
                        <Grid item>
                          <Modifiedbutton data={"Send"} swidth={"6rem"} onClick={() => handleSendEmail(selectedReport.reportId)} />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <img src={chatbg1} alt="chatbg" />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Reports;
