import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";
import cardicon from "../assets/Images/cardicon.svg";

export const editorstyles = makeStyles({
  tablisted: {
    "& .MuiTabs-indicator": {
      display: "none", // Hides the default indicator
    },
    "& .MuiTab-root": {
      color: color_exports.opacity_text_color,
      fontWeight: "600",
      fontSize: "16px",
      paddingBottom: "0px !important",
      "@media (min-width:600px) and (max-width:700px)": {
        fontSize: "14px",
      },
      "@media screen and (max-width: 599px)": {
        fontSize: "12px",
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: color_exports.primary_color,
      fontWeight: "600",
      fontSize: "16px",
      position: "relative", // Required for positioning the pseudo-element
      "@media (min-width:600px) and (max-width:700px)": {
        fontSize: "14px",
      },
      "@media screen and (max-width: 599px)": {
        fontSize: "12px",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: "65%", // Adjust this to control the underline width
        height: "2px", // Thickness of the underline
        backgroundColor: "#B8003E", // Color of the underline
      },
    },
    "& .MuiTabs-flexContainer": {
      gap: "0rem",
      "@media screen and (max-width: 599px)": {
        gap: "0rem",
      },
    },
  },
  editorbodyQuestion: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "100%",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  editorbody: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "150px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  editorpress: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "150px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  accdev: {
    overflow: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: "2px" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#33333310" },
  },
  accordianStyles: {
    marginBottom: "1rem",
    boxShadow: "none !important",
    borderRadius: "0.5rem !important",
    "&::before": {
      display: "none",
    },
  },
  accordionSummaryStyles: {
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(270deg)",
    },
    fontWeight: 700,
    alignItems: "center",
    justifyContent: "space-between",
  },
  faqsty: {
    overflow: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: "2px" },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#33333310",
    },
  },
  editorbodyterms: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "500px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
    "&.ql-active": {
      color: color_exports.primary_color,
      // stroke: color_exports.primary_color,
    },
    "& .ql-snow.ql-toolbar button:hover.ql-snow.ql-toolbar button.ql-active": {
      stroke: color_exports.primary_color,
      color: color_exports.primary_color,
    },
  },
});

export const reporteditorstyles = makeStyles({
  noresults: {
 textAlign: 'center',
 marginTop: '2rem'
  },
  noresults1: {
    textAlign: 'center',
    marginTop: '1.8rem',
    fontSize: '1.3rem',
    color: 'rgba(0, 0, 0, 0.6)'
     },
  searchpaper: {
    padding: "2rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
  },
  editorbodyQuestion: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "100%",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  editorbody: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "150px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  editorpress: {
    height: "auto",
    backgroundColor: "#fff",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "150px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
  },
  accordianStyles: {
    marginBottom: "1rem",
    boxShadow: "none !important",
    borderRadius: "0.5rem !important",
    "&::before": {
      display: "none",
    },
  },
  editorbodyterms: {
    height: "auto",
    backgroundColor: "#F1F1F1",
    padding: "0.8rem",
    borderRadius: "1rem",
    "& .ql-editor": {
      height: "250px",
    },
    "& .ql-container": {
      border: "none !important",
    },
    "& .ql-toolbar": {
      borderRadius: "0.6rem",
    },
    "&.ql-active": {
      color: color_exports.primary_color,
      // stroke: color_exports.primary_color,
    },
    "& .ql-snow.ql-toolbar button:hover.ql-snow.ql-toolbar button.ql-active": {
      stroke: color_exports.primary_color,
      color: color_exports.primary_color,
    },
  },
});

export const usercardimg = makeStyles({
  userimg: {
    backgroundImage: `url(${cardicon})`,
  },
  paper: {
    padding: "1rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
  }
});

export const notificationstyles = makeStyles({
  mainpaper1: {
    padding: "2rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
    marginTop: "2rem",
  },
});
export const subadminstyles = makeStyles({
  selectplaceholder: {
    color: "darkgray"
  },
  cardviewimage: {
    cursor: "pointer",
    width: "110px",
    height: "140px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F1F1 !important",
    marginTop: "40px",
    borderRadius: "10px",
  },
  subadminsty: {
    height: "55rem",
    overflow: "hidden",
    overflowY: "scroll",
    "&::-webkit-scrollbar": { width: "2px" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#33333310" },
  },
  subadminname: {
    color: color_exports.tex_shade_color,
    fontSize: "16px",
    fontWeight: 700,
  },
  phnnum: {
    color: color_exports.icon_color,
    fontSize: "14px",
  },
  location: {
    color: color_exports.tex_shade_color,
    fontSize: "14px",
    marginTop: "-12px",
  },
  subadminimg: {
    width: "150px",
    height: "210px",
    objectFit: "cover",
    borderRadius: "1rem",
  },
  subadminimgs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addbtn: {
    borderRadius: "10px",
    marginLeft: "10px",
  },
  cancelbtn: {
    bgcolor: "#F2F2F2 !important",
    color: "#9F9F9F",
  },
  cardviewimagecontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1F1F1",
    borderRadius: "1rem",
    height: "200px",
  },
  paper: {
    padding: "1rem",
    borderRadius: "1rem !important",
    height: "calc(100% - 3rem)",
  },
  textField: {
    padding: "0px",
    width: ({ width }) => width || "16rem",
    background: "#F1F1F1",
    borderRadius: "3rem",
    "& .MuiInputBase-root": {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
    "& fieldset": {
      border: "none",
    },
  },
  label: {
    color: "#B8003E",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Open Sans",
  },
  uploadimg: {
      width: "150px",
      height: "200px",
      marginTop: "-30px",
      borderRadius: "1rem",
      objectFit: "cover"
  },
  selectbg: {
    backgroundColor: "#F2F2F2",
  }
});
