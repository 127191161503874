import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import axios from "axios";
import { StorageClient } from "../Helpers/Helperfunctions";
import { enqueueSnackbar } from "notistack";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";
import Modifiedsearchbar from "../Components/Modifiedsearchbar";
import { debounce } from "lodash";
import Modifiedbutton from "../Components/Modifiedbutton";
import moment from "moment";
import { saveAs } from "file-saver";
import Existinguserslist from "../Components/Existinguserslist";
import RightArrow from "../Components/RightArrow";
import LeftArrow from "../Components/LeftArrow";
import { existinguserscolumns } from "../Components/Tablecolums";
import { color_exports } from "../Styles/Color_exports";
import ReactPaginate from "react-paginate";

const Existusersmanagement = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [guestType, setGuestType] = useState("existing");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [existinguserstabledata, setExistinguserstabledata] = useState([]);
  const [existingusercount, setExistingusercount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sortValue, setSortValue] = useState("serialNo");
  const [sortBy, setSortBy] = useState("DESC");
  const latestSearchText = useRef(searchText);
  const usermanagementstyle = userdetail_styles();
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#f4f4f9",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    fileInput: {
      display: "none",
    },
    label: {
      backgroundColor: "black",
      color: "#fff",
      padding: "5px 10px",
      borderRadius: "3rem",
      cursor: "pointer",
      marginBottom: "15px",
    },
    error: {
      color: "red",
      marginTop: "10px",
      fontSize: "14px",
    },
    fileName: {
      fontSize: "16px",
      fontStyle: "italic",
      color: "#555",
      marginTop: "10px",
    },
    button: {
      backgroundColor: "#b8003e",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#218838",
      },
      padding: "10px 20px",
      borderRadius: "3rem",
      marginRight: "0.7rem",
    },
    loadingSpinner: {
      marginTop: "20px",
    },
  };
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setPageNumber(selectedPage); // Update the pageNumber state to reflect the selected page
    if (tabIndex === 1 && guestType === "existing") {
      fetchExistingUserData(selectedPage);
    }
  };

  const fetchExistingUserData = useCallback(
    async (pagenumber) => {
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getAllExistingUsers`,
          req,
          {
            headers: {
              Authorization: `Bearer ${StorageClient.getItem("token")}`,
            },
          }
        );

        if (
          response?.data?.message === "Existing Users retrieved successFully"
        ) {
          const formattedData = response.data.data.rows.map((row, index) => ({
            "S.NO": (pagenumber - 1) * pageSize + index + 1, // Serial number
            Email: row.email || "Not available", // Email field
            "Created On": moment(row.createdAt).format("DD-MM-YYYY"),
          }));

          setExistinguserstabledata(formattedData); // Set table data
          setExistingusercount(response.data.data.count); // Set count
          setPageCount(Math.ceil(response.data.data.count / pageSize)); // Set pagination count
        } else {
          enqueueSnackbar(
            response?.message || "Failed to fetch existing users",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error fetching existing users:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [pageSize]
  );

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();
      if (fileType !== "csv") {
        setErrorMessage("Invalid file type. Please upload a CSV file only.");
        e.target.value = "";
        setFile(null);
        return;
      }
      setErrorMessage("");
      setFile(selectedFile);
    }
  };
  const handleSearch = () => {
    setPageNumber(1); // Ensure that the page number is reset to 1
    debouncedFetchData(1); // Fetch data for the first page
  };
  const handleFileUpload = () => {
    if (!file) {
      enqueueSnackbar("Please select a file to upload.", { variant: "error" });
      return;
    }

    setLoading(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        if (parsedData.length === 0) {
          throw new Error("The uploaded file is empty or invalid.");
        }

        // Find the email column dynamically
        const columnNames = Object.keys(parsedData[0]);
        const emailColumnName = columnNames.find((col) =>
          col.toLowerCase().includes("email")
        );

        if (!emailColumnName) {
          throw new Error(
            "Please upload a valid CSV file that includes an Email column"
          );
        }

        // Extract and clean email addresses
        const emailData = parsedData
          .map((row) => row[emailColumnName]) // Directly get email values
          .filter((email) => typeof email === "string") // Ensure it's a string
          .map((email) => email.replace(/\s/g, "")) // Remove all spaces
          .filter((email) => email) // Remove empty values
          .filter(
            (email) =>
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) && // Validate format
              !email.endsWith("s") // Exclude emails like "bhanu@gmail.coms"
          )
          .map((email) => email.toLowerCase()); // Convert email to lowercase

        if (emailData.length === 0) {
          throw new Error("No valid email addresses found in the file.");
        }

        // Remove duplicates
        const uniqueEmails = [...new Set(emailData)];

        // Send email data to backend
        sendDataToBackend(uniqueEmails);
      } catch (error) {
        setErrorMessage(error.message);
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      }
    };

    reader.readAsBinaryString(file);
  };

  const sendDataToBackend = async (emails) => {
    if (emails.length === 0) {
      enqueueSnackbar("No valid email addresses to upload.", {
        variant: "error",
      });
      setLoading(false);
      return;
    }

    const token = StorageClient.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/uploadExistingUsers`,
        { email: emails },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (
        response.status === 200 &&
        response.data?.message === "Existing User Data Processed"
      ) {
        enqueueSnackbar(
          "Existing User Data Added Successfully. Check the User Management page, Existing Users tab.",
          { variant: "success" }
        );
      } else {
        throw new Error(
          response.data?.message || "Unexpected error from the server."
        );
      }
    } catch (error) {
      console.error("Error during API call:", error);
      enqueueSnackbar(
        error.message ||
          "Failed to upload some or all emails. Please try again.",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchData = useCallback(
    debounce((pagenumber) => {
      if (tabIndex === 1 && guestType === "existing") {
        fetchExistingUserData(pagenumber);
      }
    }, 500),
    [fetchExistingUserData, tabIndex, guestType]
  );
  const handleDownloadClick2 = async () => {
    const token = StorageClient.getItem("token"); // Replace with your method of token retrieval

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await axios.post(
        `${backendUrl}/downloadExistingUsersCsvData`, // POST request
        null, // No payload in the body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token in Authorization header
          },
          responseType: "blob", // Expect a blob response for file download
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "existing-users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };
  useEffect(() => {
    latestSearchText.current = searchText;
    fetchExistingUserData(pageNumber); // Fetch data based on the current page number
  }, [searchText, debouncedFetchData, pageNumber]); // Add pageNumber to the dependency array

  useEffect(() => {
    fetchExistingUserData(latestSearchText ? 1 : pageNumber); // Fetch data based on page number, sort, and tab/guest type.
  }, [
    pageNumber,
    sortValue,
    sortBy,
    fetchExistingUserData, // Ensure the function is included for re-fetching when changed
    tabIndex, // Only call when tab changes
    guestType, // Only call when guestType changes
  ]);

  return (
    <>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tabs
            value={tabIndex}
            onChange={(event, newValue) => setTabIndex(newValue)}
            aria-label="User management tabs"
            className={usermanagementstyle.tabstyles}
          >
            <Tab label="Existing Users" />
          </Tabs>
        </Grid>

        <Grid item container md="auto" gap={2}>
          <Grid item>
            {tabIndex === 0 && (
              <Modifiedsearchbar
                placeholder="Search with Email, Date"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageNumber(1); // Ensure that the page number is reset to 1
                  debouncedFetchData(1);
                }}
              />
            )}
          </Grid>
          <Grid item>
            {/* <Modifiedbutton
              data={"Search"}
              sheight="2.5rem"
              swidth="1rem"
              onClick={handleSearch}
            /> */}
          </Grid>
        </Grid>

        {/* Stacking Choose CSV File, Upload Button, and Download Button vertically */}
        <Grid
          item
          container
          md
          direction="column"
          alignItems="end" // Aligns all child items to the right
          spacing={2}
        >
          <Grid item>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={styles.fileInput}
              id="fileInput"
            />
            <label htmlFor="fileInput" style={styles.label}>
              Choose CSV File
            </label>
          </Grid>
          {file && <Typography style={styles.fileName}>{file.name}</Typography>}
          <Grid item>
            {loading && (
              <CircularProgress size={24} style={styles.loadingSpinner} />
            )}
            <Modifiedbutton
              data={loading ? "Uploading..." : "Upload CSV File"}
              sheight="2rem"
              swidth="10rem"
              onClick={handleFileUpload}
            />
          </Grid>

          <Grid item>
            {tabIndex === 0 && (
              <Modifiedbutton
                data={"Download Existing Users Data"}
                sheight="2rem"
                swidth="15rem"
                onClick={handleDownloadClick2}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      {tabIndex === 0 && (
        <Grid container direction="column">
          <Grid item mt={2} sx={{ maxWidth: "100%!important" }}>
            <Existinguserslist
              rows={existinguserstabledata}
              columns={existinguserscolumns}
              maxheight="65vh"
              pageNumber={pageNumber}
              pageSize={pageSize}
            />
            <Typography variant="h6" style={{ marginTop: "20px" }}>
              Total Existing Users Count: {existingusercount}
            </Typography>
          </Grid>

          {/* Pagination */}
          {pageCount > 1 && (
            <Grid
              item
              container
              justifyContent={"center"}
              textAlign={"center"}
              sx={{
                "& .pageClassName": {
                  backgroundColor: color_exports.btntext_color,
                },
                "& .activeClassName": {
                  backgroundColor: color_exports.primary_color,
                },
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={<RightArrow />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<LeftArrow />}
                renderOnZeroPageCount={null}
                containerClassName="mainPageCont"
                activeClassName="activeClassName"
                activeLinkClassName="activeLinkClassName"
                pageClassName="pageClassName"
                forcePage={pageNumber - 1}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Existusersmanagement;
