import { makeStyles } from "@mui/styles";
// import { color_exports } from "./Color_exports";
// import chatbgimg from "../assets/Images/chatbgimg.png";
import chatbackground from "../assets/Images/chatbackground.png";

export const chatmessagestyles = makeStyles({
  replySender: {
    fontWeight: "700 !important", 
    color: "#b8003e",
    textAlign: "left", 
  },
  replyText: {
    fontSize: "14px",
    color: "white",
    textAlign: "left",
  },
  replyPreview: {
    background: "pink",
    padding: "8px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  replyBox: {
   background: "pink",
    padding: "5px",
    // borderLeft: "4px solid #888",
    borderRadius: "8px",
    marginBottom: "5px",
    width: "200px",
  },
  timestamp:{
    fontSize:"12px !important",
    // marginLeft: '2rem !important',
    textAlign: "end !important",
    fontWeight: "100 !important",
  },
  moreOptionsButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },  
  timestamp1:{
    fontSize:"16px !important",
    marginRight: "2rem !important",
    fontWeight: "500 !important",
    whiteSpace: "pre-line",
    textAlign: "left"
  },
  chipStyles: {
    background: "#B8003E !important",
    color: "#ffff !important",
    minWidth: "24px",
    height: "24px !important",
    // position: "absolute",
    // right: "0%",
    "& span": {
      padding: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      margin: "0.3rem",
    },
  },
  blockedMessage: {
    textAlign: "center",
  },
  senderstyle: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "15px 0px 15px 15px",
  },
  receieverstyle: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "15px 10px 15px 15px",
  },
  btnstyle: {
    marginRight: "1rem",
  },
  navbar: {
    // position: "sticky !important",
    top: "0px",
    backgroundColor: "white",
    marginTop: "-15px",
    paddingTop: "15px",
    // marginLeft: "-16px !important",
    // paddingLeft: "16px",
  },
  home: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  container: {
    border: "1px solid white",
    borderRadius: "10px",
    width: "75%",
    height: "80%",
    display: "flex",
    overflow: "hidden",
  },
  sidebar: {
    flex: 1,
    backgroundColor: "white",
  },
  chatsidebarheight: {
    height: "100%",
  },
  chat: {
    flex: 2,
    backgroundColor: "white",
    borderRadius: "15px",
  },
  userchat: {
    // padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "black",
    cursor: "pointer",
    marginLeft: "-16px",
    paddingLeft: "16px",
    width: "calc(100% + 16px) !important",

    "&:hover": {
      backgroundColor: "#F1F1F1",
    },
    "&:active": {
      backgroundColor: "#F1F1F1",
    },
  },
  userchat1: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "black",
    cursor: "pointer",
    marginTop: "8px"
  },
  username: {
    fontFamily: "Open Sans",
    color: "#595959",
    fontSize: "14px",
    fontWeight: "400",
  },
  usermessage2: {
    color: "#9D9D9D",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    marginTop: "5px",
  },
  username1: {
    fontFamily: "Open Sans",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "400",
  },
  usermessage1: {
    color: "white",
    fontSize: "13px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    marginTop: "5px",
  },
  userchatinfo1: {
    background: "#B8003E",
    margin: "-15px 0px 0px -15px",
    padding: "5px",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
        "::-webkit-scrollbar": { display: "none" },
          maxHeight: "80px",
          height: "80px",
  },
  chatmessages: {
    backgroundImage: `url(${chatbackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "10px",
    height: "calc(100% - 110px)",
    margin: "-15px 0px 0px -15px",
    overflowY: "scroll !important",
    textAlign: 'center',
    overflowX: "hidden",
  },
  input: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputstylesofchat: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "100px",
    height: "60px",
    marginRight: "1rem",
  },
  msginputfield: {
    width: "100%",
    border: "none",
    outline: "none",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    color: "black",
    backgroundColor: "white",
    marginLeft: "10px",
    resize: "none",
    marginTop: "1rem"
  },
  usermessage: {
    display: "flex",
    gap: "20px",
    marginBottom: "20px",
  },
  messageinfo: {},
  messageinformation: {
    maxWidth: "80%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  chatmsg: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "16px 16px 16px 16px",
    width: "250px",
    wordWrap: "break-word",
    whiteSpace: "pre-line",  
    marginRight: "-1rem",  
  },
  ownermessages: {
    flexDirection: "row-reverse",
  },
  dividerstyle: {
    marginLeft: "-16px !important",
    paddingRight: "32px",
  },
});
