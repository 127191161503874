import React, { useState } from "react";
import Sidebar from "../Components/Chat/Sidebar";
import Chat from "../Components/Chat/Chat";
import { chatmessagestyles } from "../Styles/Chatstyles";
import { Grid } from "@mui/material";
import chatbg1 from "../assets/Images/chatbg1.png";

const Messages = () => {
  const chatstyle = chatmessagestyles();
  const [selectedChat, setSelectedChat] = useState();
  return (
    <Grid container className={chatstyle.home} mt={3} ml={3}>
      <Grid container spacing={2} gap={3} sx={{ backgroundColor: "#F1F1F1", maxHeight: "100vh", height: "calc(100vh - 6.5rem)" }} className={chatstyle.sidebar}>
        <Grid item md={4.5} sx={{
            borderRadius: "15px",
            height: "100%",
            overflowY: "auto", // Enable scrolling
            maxHeight: "100%", // Prevent it from exceeding container height
            paddingTop: "0px !important",
            "::-webkit-scrollbar": { width: "5px" }, // Optional: Customize scrollbar width
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#ccc",
              borderRadius: "10px",
            }, // Optional: Customize scrollbar style
            "::-webkit-scrollbar-track": { backgroundColor: "#f1f1f1" }, // Optional: Customize track
          }}
          className={chatstyle.sidebar}
        >
          <Sidebar selectChat={(data) => setSelectedChat(data)} selectedroom={selectedChat} />
        </Grid>

        <Grid item md={7.2} pr={4} sx={{ height: "100%" }} className={chatstyle.chat}>
          {selectedChat ? (
            <Chat selected={selectedChat} chatroomchange={(data) => setSelectedChat(data)} />
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <img src={chatbg1} alt="chatbg" />
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Messages;
