import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Tab,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { dashboardstyles } from "../Styles/Tablistindashboardstyle";
import Modifiedgraph from "../Components/Modifiedgraph";
import Modifiedgraph2 from "../Components/Modifiedgraph2";
import Modifiedgraph3 from "../Components/Modifiedgraph3";
import Modifiedpiechart from "../Components/Modifiedpiechart";
import Modifiedpiechart2 from "../Components/Modifiedpiechart2";
import Toplocationusers from "../Components/Toplocationusers";
import { enqueueSnackbar } from "notistack";
import {
  noofmatches_api,
  getUsersAnalytics_api,
  getMembershipAnalytics_api,
  revenuegenerated_api,
  reports_api,
} from "../Apiservices/Adminservices";
import Sidebar from "../Components/Chat/Sidebar";
import Revenuechart from "../Components/Revenuechart";
import { color_exports } from "../Styles/Color_exports";
import { storystyles } from "../Styles/Component_styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import user1 from "../assets/Images/user1.png";
import Cardbgimage from "../assets/Icons/Cardbgimage";
import Cardinfo from "../Components/Cardinfo";
import Newuserinfo from "../Components/Newuserinfo";
import moment from "moment";
import Matchpiechart from "../Components/Matchpiechart";
import Revenuepiechart from "../Components/Revenuepiechart";
const Admindashboard = () => {
  const storystyle = storystyles();
  const navigate = useNavigate();
  const liststyle = dashboardstyles();
  const [favourite_tab, setFavourite_tab] = useState(1); // Default to Daily tab
  const [matchesData, setMatchesData] = useState(null);
  const [userAnalyticsData, setUserAnalyticsData] = useState(null);
  const [membershipAnalyticsData, setMembershipAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [dateError, setDateError] = useState(null);
  const [successStories, setSuccessStories] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState("");
  const [reports, setReports] = useState([]);
  const [revenueData, setRevenueData] = useState(null);

  const tabsData = [
    { tabName: "Daily", value: 1, tabWidth: "60px" },
    { tabName: "Weekly", value: 2, tabWidth: "80px" },
    { tabName: "Monthly", value: 3, tabWidth: "80px" },
    { tabName: "Cumulative", value: 4, tabWidth: "100px" },
  ];

  const handle_favouritetabs = (event, newValue) => {
    setFavourite_tab(newValue);
    if (newValue === 4) {
      setOpenDateDialog(true);
    } else {
      fetchData(newValue);
    }
  };

  const fetchData = async (reportByValue) => {
    setLoading(true);
    const now = new Date();
    // const formattedNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss Z");
    const formattedNow = moment(new Date());
    try {
      const params = {
        reportBy: reportByValue,
        fromDate: reportByValue === 4 ? fromDate : formattedNow,
        toDate: reportByValue === 4 ? toDate : formattedNow,
      };

      const matchesResponse = await noofmatches_api(params);
      if (
        matchesResponse &&
        matchesResponse.message ===
          "Successfully retrived  match Analytics data"
      ) {
        setMatchesData(matchesResponse.data);
      } else {
        enqueueSnackbar(
          matchesResponse?.message || "Failed to fetch match analytics",
          { variant: "error" }
        );
        setMatchesData([]);
      }

      const revenueResponse = await revenuegenerated_api(params);
      if (
        revenueResponse?.message ===
        "Successfully retrived  revenue Analytics data"
      ) {
        if (reportByValue === 1) {
          setRevenueData({
            revenueCount:
              revenueResponse.data.revenueData[0]?.revenueCount || 0,
            currentDayCount: revenueResponse.data.currentDayCount,
            previousDayCount: revenueResponse.data.previousDayCount,
          });
        } else {
          setRevenueData(revenueResponse.data);
        }
      } else {
        enqueueSnackbar(
          revenueResponse?.message || "Failed to fetch revenue analytics",
          { variant: "error" }
        );
        setRevenueData(null);
      }

      const userAnalyticsResponse = await getUsersAnalytics_api(params);
      if (
        userAnalyticsResponse &&
        userAnalyticsResponse.message === "Successfully retrived Users data"
      ) {
        setUserAnalyticsData(userAnalyticsResponse.data);
      } else {
        enqueueSnackbar(
          userAnalyticsResponse?.message || "Failed to fetch user analytics",
          { variant: "error" }
        );
        setUserAnalyticsData(null);
      }
      const membershipAnalyticsResponse = await getMembershipAnalytics_api(
        params
      );
      if (
        membershipAnalyticsResponse &&
        membershipAnalyticsResponse.message ===
          "Successfully retrived  membership Analytics data"
      ) {
        setMembershipAnalyticsData(membershipAnalyticsResponse.data);
      } else {
        enqueueSnackbar(
          membershipAnalyticsResponse?.message ||
            "Failed to fetch membership analytics",
          { variant: "error" }
        );
        setMembershipAnalyticsData(null);
      }
    } catch (err) {
      enqueueSnackbar(err.message || "Failed to fetch analytics", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (favourite_tab !== 4) {
      fetchData(favourite_tab);
    }
  }, [favourite_tab]);
  useEffect(() => {
    const fetchStories = async () => {
      try {
        const req = { filterType: 2, min: 0, max: 5 }; // Set filterType to 2
        const defaultAuthKey = "ACZTq7voBzX7jERFpHJECvqd3BWCW6BC"; // Use auth key
        const backendUrl = process.env.REACT_APP_BACKEND_URL; // Use environment variable for the backend URL
        const headers = {
          "Content-Type": "application/json",
          Authorization: `${defaultAuthKey}`,
        };

        const response = await axios({
          method: "POST",
          url: `${backendUrl}/getSuccessStories`, // Dynamic URL from environment
          data: req,
          headers: headers,
        });

        // Check if the response is successful
        if (response.data.message === "Successfully retrived success stories") {
          setSuccessStories(response.data.data.rows); // Set the success stories data

          if (response.data.data.imageBaseUrl) {
            setImageBaseUrl(response.data.data.imageBaseUrl); // Set the image base URL
          }
        } else {
          enqueueSnackbar(
            response.data.message || "Failed to fetch success stories",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error during fetching stories:", err);
        enqueueSnackbar("Failed to fetch stories", { variant: "error" });
      }
    };

    fetchStories();
  }, []); // Empty dependency array to ensure the fetch runs only once

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await reports_api({ min: 0, max: 5 });

        if (
          response &&
          response.message === "Successfully retrived Reports data"
        ) {
          // enqueueSnackbar("Reports fetched successfully", { variant: "success" });
          setReports(response.data.rows);
          setImageBaseUrl(response.data.imageBaseUrl);
        } else {
          console.error(response?.message || "Failed to fetch reports");
          enqueueSnackbar(response?.message || "Failed to fetch reports", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch reports", {
          variant: "error",
        });
      }
    };

    fetchReports();
  }, []);

  const handleDateDialogClose = () => {
    setOpenDateDialog(false);
  };

  const handleDateDialogSubmit = () => {
    if (fromDate && toDate) {
      fetchData(4);
      handleDateDialogClose();
    } else {
      enqueueSnackbar("Please select both dates", { variant: "warning" });
    }
  };
  const validateDates = () => {
    const today = new Date().toISOString().split("T")[0];
    const from = new Date(fromDate);
    const to = new Date(toDate);

    if (from > to) {
      return "From Date cannot be later than To Date.";
    }

    if (from > new Date(today)) {
      return "From Date cannot be in the future.";
    }

    if (to > new Date(today)) {
      return "To Date cannot be in the future.";
    }

    return null; // No error
  };

  const handleSubmit = () => {
    const error = validateDates();
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    } else {
      handleDateDialogSubmit(); // Proceed with submitting dates
      handleDateDialogClose();
    }
  };
  const handleClick = () => {
    navigate("/admin/success?tab=recent");
  };
  const pieChartData = userAnalyticsData
    ? [
        { label: "Male Users", value: userAnalyticsData.maleUsersCount },
        { label: "Female Users", value: userAnalyticsData.femaleUsersCount },
        {
          label: "Recent Registrations",
          value: userAnalyticsData.recentRegistrationsCount,
        },
        { label: "Guest Users", value: userAnalyticsData.guestUsersCount },
      ]
    : [];
  const pieChartData1 = matchesData
    ? [
        { label: "Male Accepted", value: matchesData.maleAcceptedCount },
        { label: "Female Accepted", value: matchesData.femaleAcceptedCount },
        // { label: "Total", value: matchesData.totalCount },
      ]
    : [];
  const roundedUserPercentIncreasedBy = userAnalyticsData
    ? Math.round(userAnalyticsData.userPercentIncreasedBy)
    : null;
  const roundedMembershipPercentIncreasedBy = membershipAnalyticsData
    ? Math.round(membershipAnalyticsData.membershipPercentIncreasedBy)
    : null;

  if (!membershipAnalyticsData || !membershipAnalyticsData.memberShipCounts) {
    return <div>Loading...</div>;
  }
  const pieChartData2 = revenueData
    ? [
        {
          label: "Revenue Count",
          value: revenueData.revenueCount || 0,
        },
      ]
    : [];
  return (
    <Container maxWidth={"lg"}>
      <Grid container>
        <TabContext value={favourite_tab}>
          <Grid item container justifyContent={"space-between"}>
            <Grid item md={"auto"}>
              <TabList
                className={liststyle.favorite}
                onChange={handle_favouritetabs}
                scrollButtons="on"
                variant="scrollable"
                orientation={{ xs: "horizontal", md: "none" }}
              >
                {tabsData.map((data) => (
                  <Tab
                    key={data.value}
                    value={data.value}
                    disableRipple
                    sx={{ width: data.tabWidth }}
                    className={liststyle.tabbtns}
                    label={
                      <Grid
                        container
                        alignItems={"center"}
                        padding={"0rem 0.5rem"}
                      >
                        <Grid item md sm xs textAlign={"center"}>
                          {data.tabName}
                        </Grid>
                      </Grid>
                    }
                  />
                ))}
              </TabList>
            </Grid>
            {favourite_tab === 4 && (
              <Grid item md={"auto"}>
                <TextField
                  id="from-date"
                  label="From Date"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  error={!!dateError} // Set error state based on validation
                  helperText={dateError} // Show error message
                />
                <TextField
                  id="to-date"
                  label="To Date"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  error={!!dateError} // Set error state based on validation
                  helperText={dateError} // Show error message
                />
                <DialogActions>
                  <Button onClick={handleDateDialogClose}>Cancel</Button>
                  <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
              </Grid>
            )}
          </Grid>
        </TabContext>
      </Grid>
      <Grid container spacing={2}>
        <Grid item container spacing={1}>
          {/* Users */}
          {favourite_tab === 1 && (
            <Grid item md={6}>
              <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                <Grid item className={liststyle.dashusersstyles}>
                  Users
                </Grid>
                <Grid container md={12} spacing={0}>
                  <Grid item sm={12}>
                    <Modifiedpiechart outerData={pieChartData} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* Membership */}
          {favourite_tab === 1 && (
            <Grid item md={6}>
              <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                <Grid item className={liststyle.dashusersstyles}>
                  Membership
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Modifiedpiechart2
                      data={membershipAnalyticsData.memberShipCounts}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* Users and Membership for Weekly, Monthly, and Cumulative Tabs */}
          {(favourite_tab === 2 ||
            favourite_tab === 3 ||
            favourite_tab === 4) && (
            <>
              {/* Users Pie Chart and Graph */}
              <Grid item md={6}>
                <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                  <Grid item className={liststyle.dashusersstyles}>
                    Users
                  </Grid>
                  <Grid container md={12} spacing={0}>
                    <Grid item sm={4}>
                      <Modifiedpiechart outerData={pieChartData} />
                    </Grid>
                    <Grid item sm={8}>
                      <Modifiedgraph2
                        data={userAnalyticsData}
                        reportBy={favourite_tab}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Membership Pie Chart and Graph */}
              <Grid item md={6}>
                <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                  <Grid item className={liststyle.dashusersstyles}>
                    Membership
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={4}
                      sx={{
                        "& .MuiResponsiveChart-container": {
                          width: "100% !important",
                          paddingTop: "4rem",
                        },
                      }}
                    >
                      <Modifiedpiechart2
                        data={membershipAnalyticsData.memberShipCounts}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Modifiedgraph3
                        data={membershipAnalyticsData} // Replace with actual data
                        reportBy={favourite_tab} // 1 for daily, 2 for weekly, 3 for monthly
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item mt={0.8} container spacing={2} mb={3}>
        {/* revenue */}
        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Revenue Generated
            </Grid>
            <Grid item>
              {favourite_tab === 1 ? (
                <Revenuepiechart
                  outerData={pieChartData2}
                  revenueCount={revenueData?.revenueCount || 0}
                />
              ) : (
                <Revenuechart data={revenueData} reportBy={favourite_tab} />
              )}
            </Grid>
          </Paper>
        </Grid>

        {/* matches */}
        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Number Of Matches
            </Grid>
            <Grid item>
              {favourite_tab === 1 ? (
                <Matchpiechart
                  outerData={pieChartData1}
                  maleAcceptedCount={matchesData?.maleAcceptedCount || 0}
                  femaleAcceptedCount={matchesData?.femaleAcceptedCount || 0}
                />
              ) : (
                <Modifiedgraph data={matchesData} reportBy={favourite_tab} />
              )}
            </Grid>
          </Paper>
        </Grid>

        {/* Location*/}
        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Top Locations of App Usage
            </Grid>
            <Grid item>
              <Toplocationusers />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {/* Date Range Dialog */}
      <Dialog open={openDateDialog} onClose={handleDateDialogClose}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="from-date"
            label="From Date"
            type="date"
            fullWidth
            variant="standard"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="to-date"
            label="To Date"
            type="date"
            fullWidth
            variant="standard"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateDialogClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>{" "}
      {/* Recent Success Stories */}
      <Grid container mt={2} direction={"column"}>
        <Grid item container justifyContent={"space-between"}>
          <Grid item md={"auto"} fontWeight={700} fontSize={"16px"}>
            Recent Success Stories
          </Grid>
          <Grid
            item
            md={"auto"}
            sx={{
              color: color_exports.primary_color,
              cursor: "pointer", // Add this line to display hand cursor on hover
            }}
            onClick={handleClick}
          >
            view all
          </Grid>

          <Grid container spacing={2} mt={1}>
            {successStories.length === 0 ? (
              <Grid container item justifyContent="center">
                <Grid item>Coming Soon</Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} mt={1}>
                {successStories.map((story) => (
                  <Grid item key={story.successStoryId} xs={12} sm={6} md={4}>
                    <Paper elevation={5} className={storystyle.paper}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item container justifyContent="space-between">
                          <Grid item md container>
                            <Grid item md="auto">
                              <img
                                src={
                                  story.profileImages[0]?.image1
                                    ? `${imageBaseUrl}/${story.profileImages[0].image1}`
                                    : user1
                                }
                                alt="profile"
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            </Grid>
                            <Grid item md container ml={1}>
                              <Grid item container>
                                <Grid
                                  item
                                  md="auto"
                                  className={storystyle.storyname}
                                >
                                  {story.firstName} {story.lastName},
                                </Grid>
                                <Grid item md="auto" fontSize={"12px"}>
                                  &nbsp;
                                  {new Date().getFullYear() -
                                    new Date(story.dateOfBirth).getFullYear()}
                                </Grid>
                              </Grid>
                              <Grid item container>
                                <Grid item md="auto">
                                  {story.height}ft,
                                </Grid>
                                <Grid item md="auto">
                                  &nbsp;{story.permanentCity}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md="auto">
                            <Cardbgimage />
                          </Grid>
                        </Grid>
                        <Grid item className={storystyle.successstory}>
                          {story.successStory}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* Recent Messages */}
      <Grid container spacing={2} mb={10} mt={2}>
        <Grid item md={4}>
          <Grid item fontSize={"16px"} fontWeight={700}>
            Recent Messages
          </Grid>
          <Paper elevation={5} className={liststyle.dashmessagesstyles}>
            <Grid container direction={"column"} spacing={3}>
              <Grid item>
                <Sidebar messageLimit={5} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Reports (10) */}
        <Grid item md={4}>
          <Grid item fontSize={"16px"} fontWeight={700}>
            {/* Reports ({reports.length}) */}
            Reports
          </Grid>
          <Paper
            elevation={5}
            className={liststyle.dashmessagesstyles}
            sx={{ marginTop: "0.5rem", paddingTop: "1.5rem" }}
          >
            <Grid container direction="column" spacing={1}>
              {" "}
              {/* Adjust spacing */}
              {reports.map((report) => (
                <Grid item key={report.report_id} sx={{ minHeight: "70px" }}>
                  {" "}
                  {/* Ensure consistent height */}
                  <Cardinfo report={report} imageBaseUrl={imageBaseUrl} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={4}>
          <Grid item fontSize={"16px"} fontWeight={700}>
            New Accounts Created
          </Grid>
          <Paper elevation={5} className={liststyle.dashmessagesstyles}>
            <Grid container direction={"column"} spacing={3}>
              {[1].map((item, index) => (
                <Grid item key={index}>
                  <Newuserinfo />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Admindashboard;
