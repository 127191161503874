import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../Firebase/Firebaseconfig";
import { v4 as uuidv4 } from "uuid";
import {
  filetype_checking,
  handleVideoUpload,
} from "../../Helpers/Helperfunctions";
import chataddimg from "../../assets/Images/chataddimg.png";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import FullViewMedia from "../../Models/FullViewMedia";
import {
  update_ownroom_badgecount,
  create_update_message,
} from "../../Firebase/Firebaseservices";
import { enqueueSnackbar } from "notistack";
import Modifiedbutton from "../Modifiedbutton";
import { chatmessagestyles } from "../../Styles/Chatstyles";
import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { color_exports } from "../../Styles/Color_exports";
import { handleblockusers } from "../../Firebase/Firebaseservices";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
  serverTimestamp,
} from "firebase/firestore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { deleteMessage } from "../../Firebase/Firebaseservices";
import { doc, updateDoc } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";

const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return { date: "Unknown date", time: "Unknown time" }; // Return default messages if timestamp is null
  }
  const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
  return {
    date: date.toLocaleDateString(), // Format the date as a readable string
    time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), // Format the time as HH:MM
  };
};

const Chat = ({ selected, chatroomchange }) => {
  const chatstyle = chatmessagestyles();
  const name = selected?.user_details?.name ?? "";
  // const content_type = selected?.contentType ?? "";
  const receiver_image = selected?.user_details?.photo ?? "";
  const [display_messages, setDisplay_messages] = useState([]);
  const [inputtext, setInputtext] = useState("");
  const [file, setFile] = useState(null);
  const [fileprogress, setFileprogress] = useState(0);
  const [recent, setRecent] = useState("recentMessage");
  const [content_type, setContent_type] = useState("text");
  const [isSending, setIsSending] = useState(false);
  const [replyMessage, setReplyMessage] = useState(null);
  const [hoveredMessageId, setHoveredMessageId] = useState(null);

  // Function to handle reply action
  const handleReply = (message) => {
    setReplyMessage({
      id: message.id,
      text: message.text || "", // For text messages
      senderName: message.senderName,
      contentType: message.contentType, // Store the content type (text, image, video)
      fileUrl: message.fileUrl || "", // Store the file URL for image/video
      thumbUrl: message.thumbUrl || "", // Store thumbnail for video
    });
  };

  // Function to handle editing the message
  const [editMessageId, setEditMessageId] = useState(null); // Store message ID for editing

  const handleEdit = (message) => {
    setInputtext(message.text);
    setContent_type(message.contentType);
    setEditMessageId(message.id);
    setRecent("editingMessage");
  };

  // Function to handle delete action
  const handleDelete = async (messageId) => {
    try {
      if (!messageId) return;
      await deleteMessage(messageId);
      enqueueSnackbar("Message deleted successfully", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Error deleting message", { variant: "error" });
    }
  };

  const scrollContainerRef = useRef(null);
  // const value = collection(db, "conversation_dev"); //statging
  const value = collection(db, "conversation"); //final
  const [fullViewMedia, setFullViewMedia] = useState({
    open1: false,
    media: null,
    index: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const unSubscribe = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null); // Store selected message
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const handleClick = (event, message) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl(event.currentTarget);
    setSelectedMessage(message);
    setOpenMenu(true);
    setMenuPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen1(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
    setOpen1(false);
  };
  const recent_message =
    content_type === "text"
      ? selected?.text
      : content_type === "image"
      ? "Image"
      : content_type === "video"
      ? "Video"
      : "";

  /**
   * Handling for blocking the users
   */
  const handleblock = async () => {
    try {
      const blockres = await handleblockusers([selected.roomId]);
      console.log(blockres, "blockres");

      if (blockres?.status) {
        const message = blockres?.data?.blockedUsers?.includes("admin")
          ? "User blocked successfully"
          : "User unblocked successfully";
        enqueueSnackbar(message, { variant: "success" });

        // Update the chatroom without hiding the chat details
        chatroomchange({
          ...selected,
          blockedUsers: blockres?.data?.blockedUsers,
        });

        handleClose1();
      } else {
        console.log(blockres?.message);
      }
    } catch (err) {
      console.log(err);
    }
  }; // Custom styles for chat messages

  // Function to handle opening media in full view
  const handleFullViewMedia = (data, mediaIndex) => {
    setFullViewMedia((prev) => ({
      ...prev,
      open1: true,
      media: data,
      index: mediaIndex,
    }));
  };
  const handleAction = (action) => {
    if (!selectedMessage) return;
  
    setReplyMessage(null); // ✅ Clear reply state when performing any action
  
    if (action === "edit") {
      handleEdit(selectedMessage);
    } else if (action === "delete") {
      handleDelete(selectedMessage.id);
    } else if (action === "reply") {
      handleReply(selectedMessage);
    }
  
    setAnchorEl(null);
    setOpenMenu(false);
  };
  

  // Function to fetch and update chat messages
  const get_messages = async () => {
    try {
      if (unSubscribe.current) {
        unSubscribe.current();
      }

      const getmsgs_query = query(
        // collection(db, "conversation_dev"), //staging
        collection(db, "conversation"), //final
        where("roomId", "==", selected?.roomId),
        where("nonDeletedUsers", "array-contains", "admin"),
        orderBy("createdAt", "desc")
      );

      unSubscribe.current = onSnapshot(getmsgs_query, (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        messages.sort((a, b) => a.createdAt - b.createdAt); // Sorting by createdAt
        update_ownroom_badgecount(selected); // Update badge count for the room
        setDisplay_messages(messages); // Set messages in state
      });
    } catch (err) {
      console.error("Error fetching messages: ", err);
    }
  };

  // Fetch messages whenever the selected room changes

  const handlesubmit = async () => {
    if (selected?.blockedUsers?.includes("admin")) {
      enqueueSnackbar("To send messages, please unblock the user", {
        variant: "warning",
      });
      return;
    }
  
    if (!inputtext.trim()) return;
  
    const messageText = inputtext.trim();
    setInputtext(""); // ✅ Clear input immediately after capturing value
    setReplyMessage(null); // ✅ Clear reply state
    setEditMessageId(null); // ✅ Clear edit state
    setRecent("recentMessage"); // ✅ Reset state
  
    let message = {
      contentType: "text",
      text: messageText,
      updatedAt: serverTimestamp(),
      edited: editMessageId ? true : false,
      reply: replyMessage ? true : false,
      replyMessageId: replyMessage?.id || "",
      replyMessageText: replyMessage?.text || "",
      replyMessageUserName: replyMessage?.senderName || "",
      createdAt: serverTimestamp(),
      senderID: "admin",
      roomId: selected?.roomId,
      nonDeletedUsers: [
        selected?.memberIds?.find((id) => id !== "admin"),
        "admin",
      ],
      blockedUsers: [],
      pinUserIds: [],
      platform: "web",
      senderName: "admin",
    };
  
    try {
      if (editMessageId) {
        const messageRef = doc(db, "conversation_dev", editMessageId);
        await updateDoc(messageRef, {
          text: messageText,
          updatedAt: serverTimestamp(),
          edited: true,
        });
        enqueueSnackbar("Message updated successfully", { variant: "success" });
  
        setDisplay_messages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === editMessageId
              ? { ...msg, text: messageText, edited: true }
              : msg
          )
        );
      } else {
        await create_update_message({ message, selected });
      }
    } catch (err) {
      console.error("Error sending/updating message:", err);
      enqueueSnackbar("Error sending message.", { variant: "error" });
    }
  };
  

  const handlefilechange = async (e) => {
    try {
      if (selected?.blockedUsers?.includes("admin")) {
        enqueueSnackbar("To send messages, please unblock the user", {
          variant: "warning",
        });
        return;
      }

      const uploadedFile = e.target.files[0];
      if (!uploadedFile) return;

      const fileType = await filetype_checking(uploadedFile);
      let videoThumbnail = null;

      if (fileType === "video") {
        videoThumbnail = await handleVideoUpload(uploadedFile, 1.5);
      }

      // Restrict file size for videos
      if (fileType === "video" && uploadedFile.size > 10 * 1024 * 1024) {
        enqueueSnackbar("Video size must be under 10MB", { variant: "error" });
        return;
      }

      setFile(uploadedFile);

      const uniqueFileName = `${uuidv4()}_${uploadedFile.name}`;
      const uniqueThumbnailName = videoThumbnail
        ? `${uuidv4()}_${videoThumbnail.name}`
        : null;

      // Call upload function
      await handleUpload(
        uploadedFile,
        uniqueFileName,
        videoThumbnail,
        uniqueThumbnailName,
        fileType
      );
    } catch (err) {
      console.error("Error processing file:", err);
    }
  };

  const handleUpload = async (
    file,
    fileName,
    thumbnail,
    thumbnailName,
    fileType
  ) => {
    try {
      setFileprogress(0);
      const storageRef = ref(
        storage,
        `chat/${selected?.roomId || "default"}/${fileName}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileprogress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
          enqueueSnackbar("Upload failed. Please try again.", {
            variant: "error",
          });
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          let message = {
            contentType: fileType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileSize: (file.size / (1024 * 1024)).toFixed(2) + "MB",
            createdAt: serverTimestamp(),
            senderID: "admin",
            roomId: selected?.roomId,
            nonDeletedUsers: [
              selected?.memberIds?.find((id) => id !== "admin"),
              "admin",
            ],
            blockedUsers: [],
            pinUserIds: [],
            platform: "web",
            edited: false,
            senderName: "admin",
          };

          if (fileType === "video" && thumbnail) {
            const thumbRef = ref(
              storage,
              `chat/${selected?.roomId || "default"}/${thumbnailName}`
            );
            const thumbUploadTask = uploadBytesResumable(thumbRef, thumbnail);

            thumbUploadTask.on(
              "state_changed",
              null,
              (error) => {
                console.error("Thumbnail upload failed:", error);
              },
              async () => {
                const thumbURL = await getDownloadURL(
                  thumbUploadTask.snapshot.ref
                );
                message.thumbUrl = thumbURL;
                await create_update_message({ message, selected });
              }
            );
          } else {
            await create_update_message({ message, selected });
          }

          enqueueSnackbar("File uploaded successfully!", {
            variant: "success",
          });
        }
      );
    } catch (err) {
      console.error("Error uploading file:", err);
    }
  };

  useEffect(() => {
    if (selected) {
      get_messages();
    }
  }, [selected]);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight; // Scroll to the bottom
    }
  }, [display_messages]);
  useEffect(() => {
    setEditMessageId(null);
    setReplyMessage(null);
    setInputtext(""); // Clear input field
  }, [selected]);
  
  return (
    <>
      <Grid className={chatstyle.userchatinfo1}>
        <Grid className={chatstyle.userchat1}>
          <Grid item xs={1}>
            {receiver_image ? (
              <Avatar src={receiver_image ?? ""} alt="receiverimage" />
            ) : (
              <Avatar />
            )}
          </Grid>

          <Grid className={chatstyle.userchatinfo} xs={11}>
            <span className={chatstyle.username1}>{name}</span>
            <Typography className={chatstyle.usermessage1}>
              {recent_message}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClick1}>
              <MoreVertIcon sx={{ color: color_exports.btntext_color }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <>
        {fullViewMedia.open && (
          <FullViewMedia
            open={fullViewMedia.open1}
            close={() => setFullViewMedia((prev) => ({ ...prev, open: false }))}
            data={fullViewMedia.media}
            selectedIndex={fullViewMedia.index}
          />
        )}
        <Grid className={chatstyle.chatmessages} ref={scrollContainerRef}>
          {display_messages.map((values, index) => {
            const { date, time } = formatTimestamp(values.createdAt);
            const showDate =
              index === 0 ||
              date !==
                formatTimestamp(display_messages[index - 1]?.createdAt).date;

            return (
              <React.Fragment key={values.id}>
                {showDate && (
                  <Typography className={chatstyle.date}>{date}</Typography>
                )}
                <Grid
                  className={
                    values.senderID === "admin"
                      ? chatstyle.senderstyle
                      : chatstyle.receieverstyle
                  }
                  onMouseEnter={() => setHoveredMessageId(values.id)}
                  onMouseLeave={() => setHoveredMessageId(null)}
                  style={{ position: "relative" }}
                >
                  <Grid className={chatstyle.messageContainer}>
                    {/* Message Content */}
                    <Typography>
                      {values.contentType === "text" ? (
                        <Grid className={chatstyle.chatmsg}>
                          {values.reply && values.replyMessageText && (
                            <Grid className={chatstyle.replyBox}>
                              <Typography className={chatstyle.replySender}>
                                {values.replyMessageUserName}
                              </Typography>
                              <Typography className={chatstyle.replyText}>
                                {values.replyMessageText}
                              </Typography>
                            </Grid>
                          )}
                          <Typography className={chatstyle.timestamp1}>
                            {values.text}
                          </Typography>
                          <Typography className={chatstyle.timestamp}>
                            {time}
                          </Typography>
                        </Grid>
                      ) : values.contentType === "image" ? (
                        <img
                          src={values.fileUrl}
                          alt="img"
                          style={{ width: "200px", borderRadius: "0.5rem" }}
                        />
                      ) : values.contentType === "video" ? (
                        <div style={{ width: "200px", position: "relative" }}>
                          <img
                            src={values.thumbUrl}
                            alt={values.fileName}
                            style={{
                              width: "200px",
                              aspectRatio: 16 / 10,
                              borderRadius: "0.5rem",
                            }}
                          />
                          <Typography className={chatstyle.timestamp}>
                            {time}
                          </Typography>
                        </div>
                      ) : null}
                    </Typography>
                  </Grid>

                  {/* More Options Button (Positioned Outside the Chat Message) */}
                  {hoveredMessageId === values.id && (
                    <IconButton onClick={(event) => handleClick(event, values)}>
                      <MoreVertIcon />
                    </IconButton>
                  )}

                  {/* Menu for Actions (Reply, Edit, Delete) */}
                  <Menu
                    anchorReference="anchorPosition"
                    anchorPosition={
                      menuPosition
                        ? { top: menuPosition.top, left: menuPosition.left }
                        : undefined
                    }
                    open={openMenu}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: "20ch",
                        zIndex: 9999, // Ensure it's on top
                        border: "1px solid #ccc", // Optional: Add border if needed
                        borderRadius: "8px", // Optional: Round corners
                        boxShadow: "none", // ✅ Removes the box shadow
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => handleAction("reply", selectedMessage)}
                    >
                      <ReplyIcon /> Reply
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleAction("delete", selectedMessage)}
                    >
                      <DeleteIcon /> Delete
                    </MenuItem>
                    {selectedMessage?.senderID === "admin" && (
                      <MenuItem
                        onClick={() => handleAction("edit", selectedMessage)}
                      >
                        <EditIcon /> Edit
                      </MenuItem>
                    )}
                  </Menu>
                </Grid>
              </React.Fragment>
            );
          })}

          {selected?.blockedUsers?.includes("admin") && (
            <Typography className={chatstyle.blockedMessage}>
              You blocked this user.
            </Typography>
          )}
        </Grid>

        {!selected?.blockedUsers?.includes("admin") && (
          <Grid className={chatstyle.inputstylesofchat}>
            <Grid className={chatstyle.emoji}>
              <label htmlFor={"files"}>
                <img
                  src={chataddimg}
                  alt="plus"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                id="files"
                style={{ display: "none" }}
                accept=".jpg, .png, .jpeg, .mp4, .mov, .avi, .mkv, .webm"
                onChange={handlefilechange}
              />
            </Grid>
            {replyMessage && (
              <Grid className={chatstyle.replyPreview}>
                {/* Replying to Text Message */}
                {replyMessage.contentType === "text" && (
                  <Typography style={{ fontSize: "14px", color: "#333" }}>
                    {/* <strong>Replying to {replyMessage.senderName}: </strong> " */}
                    {replyMessage.text}
                  </Typography>
                )}

                {/* Replying to Image */}
                {replyMessage.contentType === "image" &&
                  replyMessage.fileUrl && (
                    <img
                      src={replyMessage.fileUrl}
                      alt="Replied Image"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "4px",
                        marginRight: "8px",
                      }}
                    />
                  )}

                {/* Replying to Video */}
                {replyMessage.contentType === "video" &&
                  replyMessage.fileUrl && (
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        position: "relative",
                        marginRight: "8px",
                      }}
                    >
                      <img
                        src={replyMessage.thumbUrl || replyMessage.fileUrl}
                        alt="Video Thumbnail"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "4px",
                        }}
                      />
                    </div>
                  )}

                {/* Close (Cancel Reply) Button */}
                <IconButton onClick={() => setReplyMessage(null)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
            <textarea
              value={inputtext}
              placeholder="Enter your message"
              className={chatstyle.msginputfield}
              onChange={(e) => {
                setContent_type("text");
                setInputtext(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (inputtext.trim() !== "") {
                    handlesubmit();
                  }
                }
              }}
            />

            <Modifiedbutton
              data={"Send"}
              swidth={"6rem"}
              onClick={handlesubmit}
              sheight={"2.5rem"}
              className={chatstyle.btnstyle}
            />
          </Grid>
        )}
      </>
      <Menu
        anchorEl={anchorEl}
        open={open1}
        onClose={handleClose1}
        PaperProps={{ style: { maxHeight: 48 * 4.5, width: "20ch" } }}
      >
        <MenuItem onClick={handleblock}>
          {selected?.blockedUsers?.includes("admin")
            ? "Unblock User"
            : "Block User"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Chat;
