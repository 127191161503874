import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { modifiedtable_styles } from "../Styles/Modifiedstyles";

const Existinguserslist = (props) => {
  const modifiedstyle = modifiedtable_styles();
  const { maxheight, rows, columns, pageNumber, pageSize } = props;

  return (
    <div>
      <TableContainer className={modifiedstyle.tablecontainer1} sx={{ maxHeight: maxheight ?? 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell key={column?.id || index} align={column?.align || "left"} className={modifiedstyle.stickyHeader2} sx={{ minWidth: column?.minWidth }}>
                  <div className={modifiedstyle.stickyHeader3}>
                    <span>{column?.label}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => { const rowindex = index + 1; const backgroundColor = rowindex % 2 === 0 ? "#efeeeefa" : "#FFFFFF";
              return (
                <TableRow role="checkbox" tabIndex={-1} key={row?.userId || index} sx={{ backgroundColor }}>
                  {columns?.map((column) => {
                    const value =
                      column?.label === "S.NO" ? (pageNumber - 1) * pageSize + rowindex : row?.[column?.label] ?? "";

                    return (
                      <TableCell key={column?.id || index} align={column?.align || "left"} className={modifiedstyle.tablecell1}> {" "} {value}{" "} </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Existinguserslist;
